$(document).ready(() => {
  $("#homeSlider").slick({
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    arrows: false,
    appendDots: $("#homeDots"),
    autoplay: true,
    pauseOnHover: false
  });
});

import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";

import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";

$(document).ready(function() {
  const calendarEl = $("#calendar");

  if (calendarEl.length > 0) {
    if ($(window).width() < 769) {
      window.location = calendarEl.data("list");
    }
  }
});

$(document).ready(function() {
  const calendarEl = $("#calendar");

  if (calendarEl.length > 0) {
    const calendar = new Calendar(calendarEl.get(0), {
      plugins: [dayGridPlugin],
      events: calendarEl.data("events"),
      height: "parent"
    });

    calendar.render();
  }
});

$(document).ready(() => {
  const imgClass = "lzy_img";
  const imgSelector = "img." + imgClass;

  // Setup observer to observe images with the class "lzy_img"
  const imageObserver = new IntersectionObserver((entries, imgObserver) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const lazyImage = entry.target;
        lazyImage.src = lazyImage.dataset.src;
        lazyImage.classList.remove(imgClass);
        imgObserver.unobserve(lazyImage);
      }
    });
  });

  // Observer when a page loads observer all images with lzy_img
  const images = document.querySelectorAll(imgSelector);
  images.forEach(image => {
    imageObserver.observe(image);
  });
});

// Font Awesome
// Font icon set

import { config, library, dom } from "@fortawesome/fontawesome-svg-core";

// Change the config to fix the flicker
config.mutateApproach = "sync";

import {
  faSearch,
  faBars,
  faTimes,
  faAngleRight,
  faPlus,
  faMinus,
  faClock,
  faCalendarAlt,
  faLongArrowAltRight,
  faPhoneAlt,
  faAngleLeft,
  faThumbsUp,
  faMapMarkerAlt,
  faEnvelope,
  faPrint,
  faAngleDown,
  faUsers,
  faMap
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faTwitter,
  faXTwitter,
  faYoutube,
  faPinterestP,
  faInstagram,
  faFlickr
} from "@fortawesome/free-brands-svg-icons";

library.add(
  faSearch,
  faBars,
  faTimes,
  faAngleRight,
  faPlus,
  faMinus,
  faClock,
  faCalendarAlt,
  faLongArrowAltRight,
  faPhoneAlt,
  faAngleLeft,
  faThumbsUp,
  faMapMarkerAlt,
  faEnvelope,
  faFacebookF,
  faTwitter,
  faXTwitter,
  faYoutube,
  faPinterestP,
  faInstagram,
  faFlickr,
  faPrint,
  faAngleDown,
  faUsers,
  faMap
);

dom.watch();

$(document).ready(() => {
  // Pickadate for date forms
  $('#id_date, .dateinput').pickadate({
    format: 'yyyy-mm-dd',
    formatSubmit: 'yyyy-mm-dd',
    hiddenName: true,
  });

  // Prevent double form submissions
  $("form").submit(() => {
    $(this)
      .find("button[type='submit']")
      .prop("disabled", true);
  });
});

$(document).ready(() => {
  $(".accordion__btn").on("click", event => {
    $(event.currentTarget)
      .siblings(".collapse")
      .toggleClass("show");
    $(event.currentTarget)
      .children("h3")
      .children("svg")
      .toggle();
  });

  $(".block-table table").addClass("table");
});

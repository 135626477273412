$(document).ready(() => {
  $("#eventsCarousel").slick({
    dots: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: true,
    prevArrow: $("#eventsPrev"),
    nextArrow: $("#eventsNext"),
    appendDots: $("#eventsDots"),
    responsive: [
      {
        breakpoint: 991.98,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          dots: true
        }
      },
      {
        breakpoint: 767.98,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });
});

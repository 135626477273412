$(document).ready(() => {
  $("#searchOpen").on("click", function() {
    $(".search").fadeIn(350);
    $(".search input").focus();
    $(".search input").select();
    $("body").css("overflow", "hidden");
  });
  $("#searchClose").on("click", function() {
    $(".search").fadeOut(350);
    $("body").css("overflow", "auto");
  });
});
